export default [
    {
        path: 'StartPages/StartGender',
        route: 'start-gender',
    },
    {
        path: 'OnboardingPages/ObSocialProof',
        route: 'ob-social-proof',
    },
    {
        path: 'OnboardingPages/ObGoals',
        route: 'ob-goal',
    },
    {
        path: 'OnboardingPages/ObBodyType',
        route: 'ob-body-type',
    },
    {
        path: 'OnboardingPages/TargetBody',
        route: 'ob-body-type-target',
    },
    {
        path: 'OnboardingPages/ObTargetZone',
        route: 'ob-target-zones',
    },
    {
        path: 'OnboardingPages/BodyImage',
        route: 'ob-body-image',
    },
    {
        path: 'OnboardingPages/ActivityLevel',
        route: 'ob-activity-level',
    },
    {
        path: 'OnboardingPages/ObWalking',
        route: 'ob-walking',
    },
    {
        path: 'OnboardingPages/PushupsSquats',
        route: 'ob-pushups-squats',
    },
    {
        path: 'OnboardingPages/BendingKnees',
        route: 'ob-bending-knees',
    },
    {
        path: 'OnboardingPages/MealsFeel',
        route: 'ob-meals-feel',
    },
    {
        path: 'OnboardingPages/ObSleep',
        route: 'ob-sleep',
    },
    {
        path: 'OnboardingPages/ObWater',
        route: 'ob-water',
    },
    {
        path: 'OnboardingPages/ObDiets',
        route: 'ob-diets',
    },
    {
        path: 'OnboardingPages/ObHeight',
        route: 'ob-height',
    },
    {
        path: 'OnboardingPages/ObCurrentWeight',
        route: 'ob-weight',
    },
    {
        path: 'OnboardingPages/ObTargetWeight',
        route: 'ob-weight-target',
    },
    {
        path: 'OnboardingPages/ObAge',
        route: 'ob-age',
    },
    {
        path: 'OnboardingPages/FeedbackSummary',
        route: 'feedback-summary-fitlevel',
    },
    {
        path: 'OnboardingPages/Creating/CreatingA',
        route: 'creating-a',
    },
    {
        path: 'OnboardingPages/Creating/CreatingC',
        route: 'creating',
    },
    {
        path: 'OnboardingPages/Email',
        route: 'email',
    },
    {
        path: 'OnboardingPages/EmailConsent',
        route: 'email-consent',
    },
    {
        path: 'OnboardingPages/ObTagsA',
        route: 'ob-tags-a',
    },
    {
        path: 'OnboardingPages/ObTagsB',
        route: 'ob-tags-b',
    },
    {
        path: 'OnboardingPages/ObOccasion',
        route: 'ob-occasion',
    },
    {
        path: 'OnboardingPages/ObOccasionResult',
        route: 'ob-occasion-result',
    },
    {
        path: 'OnboardingPages/ObWorkout',
        route: 'ob-workout',
    },
    {
        path: 'OnboardingPages/ObStairs',
        route: 'ob-stairs',
    },
    {
        path: 'ResultPages/ResultA',
        route: 'result',
    },
    {
        path: 'PaymentLandingPages/PaymentIntroductoryA',
        route: 'payment-b1-bravo1',
    },
    {
        path: 'PaymentLandingPages/PaymentIntroductoryB',
        route: 'payment-o1-bravo1',
    },
    {
        path: 'PaymentLandingPages/UpsaleTwo',
        route: 'upsell',
    },
    {
        path: 'GeneralPages/Registration',
        route: 'registration-page',
    },
    {
        path: 'GeneralPages/ThankYou',
        route: 'thank-you',
    },
    {
        path: 'PaymentLandingPages/PaymentD1Bravo1',
        route: 'payment-d1-bravo1',
    },
    {
        path: 'PaymentLandingPages/PaymentG1/PaymentG1Foxtrot1',
        route: 'payment-g1-foxtrot1',
    },
    {
        path: 'PaymentLandingPages/PaymentA1Foxtrot1',
        route: 'offer-a1-foxtrot1',
    },
];
