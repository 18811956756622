import * as actionTypes from './actionTypes';

// helpers
import { getKgWeight, getNumberSystem } from 'helpers/utils';

// constants
import { DEFAULT_EMPTY_URL_PARAMS } from 'constants/onboardingScreensList';

// interfaces
import { OnboardingStore } from 'types/onboarding/onboardingStore';
import { InferValueTypes } from 'types/commonInterfaces';

import * as action from './actions';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const initialState: OnboardingStore = {
    gender: 'female',
    goal: null,
    current_body: null,
    target_bodytype: null,
    target_zone: null,
    target_weight: null,
    activityLevel: null,
    age: 0,
    tall: null,
    current_weight: null,
    measureSystem: 'IMPERIAL',
    onboardingScreensList: {},
    urlParams: DEFAULT_EMPTY_URL_PARAMS,
    occasion: null,
    diet: null,
    workoutLevel: null,
};

const OnboardingReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case actionTypes.SET_GENDER:
            localStorage.setItem('gender', action.payload);

            return { ...state, gender: action.payload };
        case actionTypes.SET_CURRENT_BODY:
            return { ...state, current_body: action.payload };
        case actionTypes.SET_GOAL:
            return { ...state, goal: action.payload };
        case actionTypes.SET_TARGET_BODY:
            return { ...state, target_bodytype: action.payload };
        case actionTypes.SET_TARGET_ZONE:
            return { ...state, target_zone: action.payload };
        case actionTypes.SET_CURRENT_WEIGHT: {
            const currentWeight = getKgWeight(action.payload);
            const measureSystemValue = getNumberSystem(action.payload.unit);

            return { ...state, current_weight: currentWeight, measureSystem: measureSystemValue };
        }
        case actionTypes.SET_TARGET_WEIGHT: {
            const targetWeight = getKgWeight(action.payload);
            const measureSystemValue = getNumberSystem(action.payload.unit);

            return { ...state, target_weight: targetWeight, measureSystem: measureSystemValue };
        }
        case actionTypes.SET_ACTIVITY_LVL:
            return { ...state, activityLevel: action.payload };
        case actionTypes.SET_AGE:
            return { ...state, age: action.payload };
        case actionTypes.SET_TALL: {
            const tall = action.payload.value;
            const measureSystemValue = getNumberSystem(action.payload.unit);

            return { ...state, tall, measureSystem: measureSystemValue };
        }
        case actionTypes.SET_MEASURE_SYSTEM:
            return { ...state, measureSystem: action.payload };
        case actionTypes.SET_ONBOARDING_SCREENS_LIST:
            return { ...state, onboardingScreensList: action.payload };
        case actionTypes.SET_URL_PARAMS: {
            const stringifyData = JSON.stringify(action.payload);

            localStorage.setItem('urlParams', stringifyData);

            return { ...state, urlParams: action.payload };
        }
        case actionTypes.SET_OCCASION: {
            return { ...state, occasion: action.payload };
        }
        case actionTypes.SET_DIET: {
            return { ...state, diet: action.payload };
        }
        case actionTypes.SET_WORKOUT_LVL: {
            return { ...state, workoutLevel: action.payload };
        }

        default:
            return state;
    }
};

export default OnboardingReducer;
