import React from 'react';

// helpers
import classNames from 'classnames/bind';

// styles
import styles from './Logo.module.css';

// images
import LogoDefault from './img/Logo.svg';

const cx = classNames.bind(styles);

export default function Logo(props) {
    const { centered, white, isOnboarding, onboardingLogo, logoFooter } = props;

    const logoClass = cx({
        logo: true,
        centered,
        white,
        isOnboarding,
    });
    const logoImgClass = cx({
        logoImgDefault: true,
        logoImgOnboarding: isOnboarding && onboardingLogo,
        logoImgFastEasy: true,
        logoFooter: logoFooter,
    });

    return (
        <div className={logoClass}>
            <LogoDefault className={logoImgClass} />
        </div>
    );
}
