import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import PublicRouter from './routers/public';
import { setGender } from 'redux/Onboadring/actions';
import { setFlowLink } from 'redux/Testania/actions';

export function Routers() {
    const dispatch = useDispatch();

    const params = new URLSearchParams(window.location.search);
    const paramsGender = params.get('gender');

    useEffect(() => {
        dispatch(setFlowLink(location.href));
    }, []);

    if (paramsGender) {
        dispatch(setGender(paramsGender));
    }

    return (
        <Switch>
            {PublicRouter.map((route) => (
                <Route key={route.key} {...route} />
            ))}
        </Switch>
    );
}
