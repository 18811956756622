// routes
import ROUTES_MAP from 'router/routesMap';

// constants
import { PAYMENT_FLOW_PERIODS } from 'constants/payments';
import { SCREEN_ID_REGEX } from 'constants/regex';

// helpers
import { replaceUnderscore, isEmpty } from 'helpers/utils';

export const checkProductPeriods = ({ periodsArr }) => {
    return periodsArr.every((period) => PAYMENT_FLOW_PERIODS.includes(period));
};

export const checkLtv = ({ products }) => {
    return products.every((product) => typeof product?.ltv === 'number' && product?.ltv > 0);
};

export const getArrayFromObjectByFieldName = (arrObjects, key) => {
    return arrObjects.map((element) => element[key]);
};

export const checkPreselectedField = ({ products, withImportantOrder }) => {
    if (withImportantOrder) {
        const averageProductValue = products.length / 2;

        return products.slice(0, averageProductValue);
    }

    return products.some((product) => product.is_preselected === true);
};

export const checkValuePairsIdentityByFieldName = ({ products, fieldName }) => {
    const countValues = products.reduce((acc, curr) => {
        const fieldValue = curr[fieldName];

        acc[fieldValue] = (acc[fieldValue] || 0) + 1;

        return acc;
    }, {});

    return Object.values(countValues).every((value) => value === 2);
};

export const checkProductsDividedByTwo = ({ products }) => {
    return products.length % 2 === 0;
};

const checkFlow = (flow) => {
    if (Array.isArray(flow)) {
        for (const { id } of flow) {
            if (!SCREEN_ID_REGEX.test(id)) {
                return false;
            }
        }
    } else {
        return SCREEN_ID_REGEX.test(flow?.id);
    }

    return true;
};

const checkIds = (pagesData) => {
    for (const { id } of pagesData) {
        if (!ROUTES_MAP.some(({ route }) => route === replaceUnderscore(id))) {
            console.log(`%c Invalid page id: ${id}`, 'color:red;');

            return false;
        }
    }

    return true;
};

export const checkConfig = ({ onboarding_flow, payment_flow, start_page }) => {
    const isValidStartFlow = checkFlow(start_page);
    const isValidOnboardingFlow = checkFlow(onboarding_flow);
    const isValidPaymentFlow = checkFlow(payment_flow);

    return isValidStartFlow && isValidOnboardingFlow && isValidPaymentFlow;
};

export const checkPagesId = ({ onboarding_flow, payment_flow, start_page }) => {
    const pagesData = [];

    onboarding_flow && pagesData.push(...onboarding_flow);
    payment_flow && pagesData.push(...payment_flow);
    !isEmpty(start_page) && pagesData.push(start_page);

    return checkIds(pagesData);
};
