import { Main } from 'wikr-core-analytics';

// constants
import { COOKIE_GROUP_LIST, OTHER_ANALYTIC } from 'constants/analytics';

// helpers
import { getAnalyticInitList } from './helper';

export default class OneTrust {
    #instance;

    constructor(OneTrust) {
        this.#instance = OneTrust;
        this.isAllowedAll = false;
    }

    init() {
        this.#instance.OnConsentChanged((oneTrustEvent) => {
            const isAnalyticsInited = localStorage.getItem('allAnalyticInited') || false;

            const { detail } = oneTrustEvent;

            if (COOKIE_GROUP_LIST.every((groupId) => detail.includes(groupId))) {
                this.isAllowedAll = true;
            }

            const activeSystems = getAnalyticInitList(detail);

            if (activeSystems.length && !isAnalyticsInited) {
                Main.initSystems({ activeSystems });

                localStorage.setItem('allAnalyticInited', 'true');
            }
        });
    }

    rejectAll() {
        this.#instance.RejectAll();
    }

    allowAll() {
        Main.initSystems({ activeSystems: OTHER_ANALYTIC });

        this.isAllowedAll = true;
        this.#instance.AllowAll();

        localStorage.setItem('allAnalyticInited', 'true');
    }
}
