export const SET_GENDER = 'SET_GENDER';
export const SET_GOAL = 'SET_GOAL';
export const SET_CURRENT_BODY = 'SET_CURRENT_BODY';
export const SET_SLEEP_DURATION = 'SET_SLEEP_DURATION';
export const SET_TARGET_BODY = 'SET_TARGET_BODY';
export const SET_TARGET_ZONE = 'SET_TARGET_ZONE';
export const SET_ACTIVITY_LVL = 'SET_ACTIVITY_LVL';
export const SET_TARGET_WEIGHT = 'SET_TARGET_WEIGHT';
export const SET_CURRENT_WEIGHT = 'SET_CURRENT_WEIGHT';
export const SET_AGE = 'SET_AGE';
export const SET_TALL = 'SET_TALL';
export const SET_MEASURE_SYSTEM = 'SET_MEASURE_SYSTEM';
export const SET_ONBOARDING_SCREENS_LIST = 'SET_ONBOARDING_SCREENS_LIST';
export const INIT_AUTO_LOGIN = 'INIT_AUTO_LOGIN';
export const SET_URL_PARAMS = 'SET_URL_PARAMS';
export const SET_YOGA_LVL = 'SET_YOGA_LVL';
export const SET_OCCASION = 'SET_OCCASION';
export const SET_DIET = 'SET_DIET';
export const SET_WORKOUT_LVL = 'SET_WORKOUT_LVL';
