import api from 'apiSingleton';

// constants
import { onboardingScreensList } from 'constants/onboardingScreensList';
import { instructionsPagesUrlList } from 'constants/instructionsPagesUrlList';
import { COUNTRIES_IMPERIAL_SYSTEM } from 'constants/countriesList';

// helpers
import { deleteSlash, isEmpty, replaceUnderscore } from 'helpers/utils';

// actions
import { setCompleteOBs, setCurrenyBranchName, setTestaniaLoginStatus } from 'redux/Testania/actions';
import { setIsPaid, setUpdatedAtTime, setUserId } from 'redux/User/actions';
import { setMeasure } from 'redux/Onboadring/actions';

export const isInstructionPage = () => {
    const path = deleteSlash(window.location.pathname);

    return instructionsPagesUrlList.includes(path);
};

export const getBranchNameFromLocalStorage = () => {
    return localStorage.getItem('config') && JSON.parse(localStorage.getItem('config'))?.branch_name;
};

export const getConfigBy = (name, defaultValue) => {
    const params = new URLSearchParams(window.location.search);

    return params.get(name) || getBranchNameFromLocalStorage() || defaultValue;
};

export const getCheckFlow = (payment_flow) => (flowId) => payment_flow.find((item) => item.id === flowId);

export const getValidatedStatus = (rules) => {
    for (const rule of rules) {
        if (!rule.exec(rule.args)) {
            console.log('%c Flow validation error - ' + rule.message, 'color:red;');

            return false;
        }
    }

    return true;
};

export const getScreenList = (onboardingFlow) => {
    let count = 1;

    return onboardingFlow.reduce((screenList, { name }) => {
        const onboardingFlowPage = replaceUnderscore(name);

        if (onboardingScreensList.includes(onboardingFlowPage)) {
            return { ...screenList, [onboardingFlowPage]: { index: count++ } };
        }

        return screenList;
    }, {});
};

export const getIsLogin = async (testaniaLoginStatus, token) => {
    return testaniaLoginStatus === 'true' || Boolean(token) || false;
};

const getUserSetData = async (dispatch) => {
    let user = {};

    try {
        user = await api.user.getUser();

        if (!isEmpty(user)) {
            dispatch(setUserId(user.user_id));
            dispatch(setIsPaid(user.is_paid));
            dispatch(setUpdatedAtTime(new Date()));
        }
    } catch (e) {
        console.error('getUser error', e);
    }

    return Boolean(user?.is_paid);
};

// TODO: check need or no
export const getIsPaidStatus = async (dispatch) => {
    const isPaidStatus = await getUserSetData(dispatch);

    dispatch(setIsPaid(isPaidStatus));

    return isPaidStatus;
};

export const setCurrentBranchToStore = async (branch, dispatch) => {
    dispatch(setCurrenyBranchName(branch));
};

export const setCompleteOBsToStore = async (branch, dispatch) => {
    dispatch(setCompleteOBs(branch));
};

export const setTestaniaLoginStatusToStore = async (status, dispatch) => {
    dispatch(setTestaniaLoginStatus(status));
};

export const setInitMeasure = async (country, dispatch) => {
    const measure = COUNTRIES_IMPERIAL_SYSTEM.includes(country) ? 'IMPERIAL' : 'METRIC';

    dispatch(setMeasure(measure));
};

export const loginAction = async () => {};

export const paidAction = async () => {};

export const startAction = async (setStartStatus) => {
    setStartStatus(true);
};
