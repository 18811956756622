import React from 'react';
import styles from './Loader.module.css';

const Placeholder = () => {
    return (
        <div className={styles.ldsSpinner}>
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
        </div>
    );
};

export default Placeholder;
