import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import styles from './ModalClose.module.css';

ModalClose.defaultProps = {
    onClick: () => {},
};

ModalClose.propTypes = {
    onClick: PropTypes.func,
};

function ModalClose({
    notificationModalIcon,
    isLeftClose,
    onClick,
    isFixed,
    isSmallClose,
}: {
    notificationModalIcon?: boolean;
    isLeftClose?: boolean;
    onClick: () => void;
    isFixed?: boolean;
    isSmallClose?: boolean;
}) {
    const cx = classNames.bind(styles);
    const modalStyles = cx({
        closePopup: true,
        closePopupFixed: isFixed,
        notificationModal__close: notificationModalIcon,
        closePopup__left: isLeftClose,
        closePopup__small: isSmallClose,
    });
    const handleClick = () => {
        onClick && onClick();
    };

    return <span className={modalStyles} onClick={handleClick} />;
}

export default ModalClose;
