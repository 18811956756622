import { createSelector } from 'reselect';

// types
import { RootState } from '../rootReducer';

const selectUser = (state: RootState) => state.user;

const selectEmail = createSelector(selectUser, (user) => user.email);
const selectOrderId = createSelector(selectUser, (user) => user.orderId);
const selectUserId = createSelector(selectUser, (user) => user.user_id);
const selectEmailStatusMessage = createSelector(selectUser, (user) => user.emailStatus.message);
const selectEmailStatusIsValid = createSelector(selectUser, (user) => user.emailStatus.isValid);
const selectIsLoading = createSelector(selectUser, (user) => user.isLoading);
const selectToken = createSelector(selectUser, (user) => user.token);
const selectPaymentType = createSelector(selectUser, (user) => user.payment_type);
const selectGuideId = createSelector(selectUser, (user) => user.guideId);
const selectUserLoader = createSelector(selectUser, (user) => user.loader);
const selectCountry = createSelector(selectUser, (user) => user.country);
const selectLastUpdateAt = createSelector(selectUser, (user) => user.lastUpdateAt);
const selectIsPaid = createSelector(selectUser, (user) => user.isPaid);
const selectUserPlatform = createSelector(selectUser, (user) => user.platform);
const selectDeepLinks = createSelector(selectUser, (user) => user.deepLink);

export {
    selectUser,
    selectEmail,
    selectOrderId,
    selectUserId,
    selectEmailStatusMessage,
    selectEmailStatusIsValid,
    selectIsLoading,
    selectToken,
    selectPaymentType,
    selectUserLoader,
    selectCountry,
    selectLastUpdateAt,
    selectIsPaid,
    selectUserPlatform,
    selectGuideId,
    selectDeepLinks,
};
