import * as actionTypes from './actionTypes';
import { updateGlobalLoaderVisibility } from 'helpers/utils';

export function hideHeader(status: boolean) {
    return {
        type: actionTypes.HIDE_HEADER,
        payload: status,
    } as const;
}

export function hideProgressBar(status: boolean) {
    return {
        type: actionTypes.HIDE_PROGRESS_BAR,
        payload: status,
    } as const;
}

export function showModal(payload: boolean) {
    return {
        type: actionTypes.SHOW_MODAL,
        payload: payload,
    } as const;
}

export function hideModal() {
    return {
        type: actionTypes.HIDE_MODAL,
    } as const;
}

export function setAppLoader(loaderState: boolean) {
    updateGlobalLoaderVisibility(loaderState);

    return {
        type: actionTypes.SET_LOADER_STATE,
        payload: loaderState,
    } as const;
}

export function initFrontChat(status: boolean) {
    return {
        type: actionTypes.INIT_FRONT_CHAT,
        payload: status,
    } as const;
}
