import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// helpers
import { isAttributionFlow } from 'helpers/utils';
import { setAnalyticData, setMirrorIcon, setUrlParams, setUserPlatform } from './helpers';

// services
import { initAmazonAnalytic, initHotjar } from 'services/Analytics';

// redux
import { setAppLoader } from 'redux/UiEffects/actions';
import { selectUser } from 'redux/User/selectors';
import { selectTestaniaName } from 'redux/Testania/selectors';
import { selectUrlParams } from 'redux/Onboadring/selectors';

function useAppInit() {
    const dispatch = useDispatch();

    const prevCountry = useRef();
    const prevAbTestName = useRef();

    const { country } = useSelector(selectUser);
    const abTestName = useSelector(selectTestaniaName);
    const urlParams = useSelector(selectUrlParams);

    const setNewPropsToRef = () => {
        prevCountry.current = country;
        prevAbTestName.current = abTestName;
    };

    useEffect(() => {
        const testaniaStatus = localStorage.getItem('testaniaResponseStatus');

        setNewPropsToRef();
        setAnalyticData({ country, abTestName, urlParams });
        setMirrorIcon();
        setUrlParams(dispatch);
        setUserPlatform(dispatch);

        !isAttributionFlow() && initAmazonAnalytic();

        if (testaniaStatus || isAttributionFlow()) {
            dispatch(setAppLoader(false));
        }

        initHotjar();
    }, []);

    useEffect(() => {
        if (prevCountry.current !== country || prevAbTestName.current !== abTestName) {
            setAnalyticData({ country, abTestName, urlParams });
            setNewPropsToRef();
        }
    }, [country, abTestName, urlParams]);
}

export default useAppInit;
