import { useState } from 'react';

// helpers
import { getFaviconElement } from 'helpers/utils';

interface ThemeConfigScheme {
    inlineStyle: object;
    themeStyle: object;
    faviconUrl: string;
    logoUrl: string;
}

const useThemeConfiguration = (themeConfig: Record<string, ThemeConfigScheme>) => {
    const [theme, setTheme] = useState(null);

    if (!theme) {
        const hostName = window.location.host;

        const { themeStyle, faviconUrl } = themeConfig?.[hostName] || themeConfig['default'];

        const faviconElement = getFaviconElement() as HTMLLinkElement;

        faviconElement.href = faviconUrl;

        setTheme(themeStyle);
    }

    return theme;
};

export default useThemeConfiguration;
