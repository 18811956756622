import { createSelector } from 'reselect';

// types
import { RootState } from '../rootReducer';

const selectOnboarding = (state: RootState) => state.onboarding;

const selectGender = createSelector(selectOnboarding, (onboarding) => onboarding.gender);
const selectActivityLevel = createSelector(selectOnboarding, (onboarding) => onboarding.activityLevel);
const selectAge = createSelector(selectOnboarding, (onboarding) => onboarding.age);
const selectCurrentBody = createSelector(selectOnboarding, (onboarding) => onboarding.current_body);
const selectCurrentWeight = createSelector(selectOnboarding, (onboarding) => onboarding.current_weight);
const selectGoal = createSelector(selectOnboarding, (onboarding) => onboarding.goal);
const selectMeasureSystem = createSelector(selectOnboarding, (onboarding) => onboarding.measureSystem);
const selectOnboardingScreensList = createSelector(selectOnboarding, (onboarding) => onboarding.onboardingScreensList);
const selectTall = createSelector(selectOnboarding, (onboarding) => onboarding.tall);
const selectTargetWeight = createSelector(selectOnboarding, (onboarding) => onboarding.target_weight);
const selectTargetZone = createSelector(selectOnboarding, (onboarding) => onboarding.target_zone);
const selectUrlParams = createSelector(selectOnboarding, (onboarding) => onboarding.urlParams);
const selectTargetBodyType = createSelector(selectOnboarding, (onboarding) => onboarding.target_bodytype);
const selectOccasion = createSelector(selectOnboarding, (onboarding) => onboarding.occasion);

export {
    selectOnboarding,
    selectGender,
    selectCurrentWeight,
    selectActivityLevel,
    selectAge,
    selectCurrentBody,
    selectGoal,
    selectMeasureSystem,
    selectOnboardingScreensList,
    selectTall,
    selectTargetWeight,
    selectTargetZone,
    selectUrlParams,
    selectTargetBodyType,
    selectOccasion,
};
