import React, { createContext, useContext } from 'react';

// hooks
import useAnalyticsInit from 'hooks/useAnalyticsPaymentInit';
import useOneTrustLoaded from 'hooks/useOneTrustLoaded';

const OneTrustContext = createContext(null);

export const OneTrustProvider = ({ children }) => {
    const { oneTrust } = useOneTrustLoaded();

    useAnalyticsInit(oneTrust);

    return <OneTrustContext.Provider value={{ oneTrust }}>{children}</OneTrustContext.Provider>;
};

export const useOneTrust = () => useContext(OneTrustContext);
