import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-xhr-backend';
import ICU from 'i18next-icu';

const localeFromStorage = localStorage.getItem('language');

i18n.use(backend)
    .use(ICU)
    .use(initReactI18next)
    .init({
        lng: localeFromStorage,
        fallbackLng: ['en'],
        ns: ['translation'],
        preload: ['en'],
        debug: false,
        react: {
            useSuspense: false,
        },
        nsSeparator: false,
        keySeparator: false,
    });

export default i18n;
