import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { Routers } from './router';

// hooks
import useAppInit from './hooks/useAppInit';

// components
import NotificationModal from 'components/NotificationModal/NotificationModal';
import ReleaseConfig from 'components/ReleaseConfig/ReleaseConfig';
import InitTestania from 'pages/GeneralPages/InitTestania';
import AppHeader from './components/AppHeader';

// services
import { OneTrustProvider } from 'services/OneTrust';
import { ThemeProvider } from 'services/Theme';

// styles
import './styles.css';

const App = () => {
    useAppInit();

    return (
        <OneTrustProvider>
            <ThemeProvider>
                <Router>
                    <AppHeader />
                    <ReleaseConfig />
                    <React.Suspense fallback={null}>
                        <InitTestania />
                        <Routers />
                    </React.Suspense>
                </Router>
                <NotificationModal />
            </ThemeProvider>
        </OneTrustProvider>
    );
};

export default App;
