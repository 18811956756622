import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactModal from 'react-modal';
import { Trans } from 'react-i18next';

// redux
import { hideModal } from 'redux/UiEffects/actions';
import { selectUIEffects } from 'redux/UiEffects/selectors';

// helpers
import { getProjectEmail } from 'helpers/utils';

// components
import ModalClose from 'components/ModalClose/ModalClose';
import Button from 'components/Button';
import Title from 'components/Title';
import Text from 'components/Text';

// styles
import styles from './NotificationModal.module.css';

// images
import IconError from './img/issueProblem.svg';
import IconInfo from './img/issueInfo.svg';

ReactModal.setAppElement('#app');

function NotificationModal() {
    const dispatch = useDispatch();

    const { issueType, issueDescription, issueTitle, modalIsOpen, onClose } = useSelector(selectUIEffects);

    const handleClose = () => {
        dispatch(hideModal());
        onClose && onClose();
    };

    return (
        <ReactModal
            shouldCloseOnEsc={false}
            isOpen={modalIsOpen}
            onRequestClose={handleClose}
            contentLabel="Modal"
            className={styles.notificationModal}
            overlayClassName={styles.Overlay}
            bodyOpenClassName={styles.ReactModalBodyOpen}
        >
            <ModalClose onClick={handleClose} notificationModalIcon />
            <Choose>
                <When condition={issueType === 'ERROR'}>
                    <IconError className={styles.notificationModalIcon} />
                </When>
                <Otherwise>
                    <IconInfo className={styles.notificationModalIcon} />
                </Otherwise>
            </Choose>
            <Title h3>
                <Trans>{issueTitle}</Trans>
            </Title>
            <Text small grey globalClassNames={['mb-s']}>
                <Trans>{issueDescription}</Trans>
            </Text>
            <Text small globalClassNames={['colorBrand', 'mb-m']}>
                <a href={'mailto:' + getProjectEmail()}>{getProjectEmail()}</a>
            </Text>
            <Button onClick={handleClose} primary notificationBtn globalClassNames={['mb-l']} title="GOT IT" />
        </ReactModal>
    );
}

export default NotificationModal;
