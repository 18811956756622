import { useEffect } from 'react';
import { useSelector } from 'react-redux';

// redux
import { selectIsPaymentScreen } from 'redux/Payment/selectors';

const useAnalyticsPaymentInit = (oneTrust) => {
    const isPaymentScreen = useSelector(selectIsPaymentScreen);
    const isAllAnalyticsAllowed = localStorage.getItem('allAnalyticInited') || false;

    useEffect(() => {
        if (isPaymentScreen && !isAllAnalyticsAllowed) {
            oneTrust?.allowAll();
        }
    }, [isPaymentScreen]);
};

export default useAnalyticsPaymentInit;
