import config from 'config';

import { systemNames } from 'wikr-core-analytics';

export const STRICTLY_NECESSARY_COOKIE = 'C0001';
export const PERFORMANCE_COOKIE = 'C0002';
export const FUNCTIONAL_COOKIE = 'C0003';
export const TARGETING_COOKIE = 'C0004';
export const SOCIAL_MEDIA_COOKIE = 'C0005';

export const COOKIE_GROUP_LIST = [STRICTLY_NECESSARY_COOKIE, PERFORMANCE_COOKIE, FUNCTIONAL_COOKIE, TARGETING_COOKIE];


export const COOKIE_ANALYTIC_LIST = {
    [STRICTLY_NECESSARY_COOKIE]: [],
    [PERFORMANCE_COOKIE]: [],
    [FUNCTIONAL_COOKIE]: [],
    [TARGETING_COOKIE]: [
        {
            name: 'ga',
            id: config.GOOGLE_ID,
        },
        {
            name: 'pixel',
            id: config.PIXEL_ID,
        },
    ],
    [SOCIAL_MEDIA_COOKIE]: [],
};

export const OTHER_ANALYTIC = [
    {
        name: systemNames.pixel,
        id: config.PIXEL_ID,
    },
    {
        name: systemNames.ga,
        id: config.GOOGLE_ID,
    },
];
