import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// redux
import { selectHideHeader, selectHideProgressBar } from 'redux/UiEffects/selectors';
import { selectOnboardingScreensList } from 'redux/Onboadring/selectors';

// components
import Logo from 'components/Logo';
import { Header } from 'libComponents';
import OnboardingStepper from 'components/OnboardingStepper';

function AppHeader() {
    const [isOnboarding, setIsOnboarding] = useState(false);
    const location = useLocation();

    const isHideHeader = useSelector(selectHideHeader);
    const onboardingScreensList = useSelector(selectOnboardingScreensList);
    const isHideProgressBar = useSelector(selectHideProgressBar);

    const currentLocationName = location.pathname.replace(/(\/([a-z]{2})\/)|\//g, '');

    useEffect(() => {
        setIsOnboarding(onboardingScreensList[currentLocationName] && !isHideProgressBar);
    }, [location]);

    const LeftContent = () =>
        isOnboarding ? (
            <OnboardingStepper
                onboardingScreensList={onboardingScreensList}
                currentLocationName={currentLocationName}
            />
        ) : (
            <Logo />
        );

    return (
        isHideHeader || <Header className={isOnboarding && 'noSeparationHeader'} backgroundColor="surface-main" left={<LeftContent />} />
    );
}

export default AppHeader;
