import { Provider } from 'wikr-core-analytics';

// helpers
import {
    getDeviceOS,
    getFaviconElement,
    isFirstMirror,
    isEmpty,
    getUrlParams as getUrlParamsUtil,
} from 'helpers/utils';

// services
import sentry from 'services/Sentry/SentryInstance';

// actions
import { setUrlParams as setUrlParamsToStore } from 'redux/Onboadring/actions';
import { setPlatform } from 'redux/User/actions';

// constants
import { ERROR_LEVELS, SENTRY_ANALYTIC } from 'constants/sentry';

const FAVICON_MIRROR = '/favicones/faviconMirror.png';

const getResolveParams = async (value) => {
    return new Promise((resolve) => {
        resolve(value);
    });
};

export const setAnalyticData = async ({ country, abTestName, urlParams }) => {
    await Provider.provideData({
        country: async () => await getResolveParams(country),
        abTestName: async () => await getResolveParams(abTestName),
        urlParams: async () => await getResolveParams(urlParams),
        clientHandlerFn: (error) => sentry.logError(error, SENTRY_ANALYTIC, ERROR_LEVELS.ERROR, {}),
    });
};

export const setMirrorIcon = () => {
    if (isFirstMirror()) {
        getFaviconElement().href = FAVICON_MIRROR;
    }
};

export const setUrlParams = (dispatch) => {
    const params = getUrlParamsUtil();

    if (!isEmpty(params)) dispatch(setUrlParamsToStore(params));
};

export const setUserPlatform = (dispatch) => {
    const platform = getDeviceOS();

    dispatch(setPlatform(platform));
};
