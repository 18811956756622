export const SET_EMAIL = 'USER/SET_EMAIL';
export const SET_ORDER_ID = 'USER/SET_ORDER_ID';
export const SET_USER_ID = 'USER/SET_USER_ID';
export const SET_USER_TOKEN = 'USER/SET_USER_TOKEN';
export const SET_GUIDE_ID = 'USER/SET_GUIDE_ID';
export const SET_COUNTRY = 'USER/SET_COUNTRY';

export const UPDATE_INFO = 'USER/UPDATE_INFO';
export const UPDATE_INFO_FAIL = 'USER/UPDATE_INFO_FAIL';
export const SET_LOADER = 'USER/SET_LOADER';

export const SIGN_UP = 'USER/SIGN_UP';
export const SIGN_UP_SUCCESS = 'USER/SIGN_UP_SUCCESS';
export const SIGN_UP_FAIL = 'USER/SIGN_UP_FAIL';

export const UPDATE_SENDPULS_MAILING = 'UPDATE_SENDPULS_MAILING';

export const SET_IS_PAID = 'USER/SET_IS_PAID';
export const SET_UPDATED_AT_TIME = 'USER/SET_UPDATED_AT_TIME';

export const SET_IS_AUTOLOGIN_COMPLETE = 'USER/SET_IS_AUTOLOGIN_COMPLETE';

export const SET_PLATFORM = 'USER/SET_PLATFORM';

export const SET_IS_LOADING = 'USER/SET_IS_LOADING';

export const GET_DEEP_LINK = 'USER/GET_DEEP_LINK';
export const GET_DEEP_LINK_SUCCESS = 'USER/GET_DEEP_LINK_SUCCESS';
