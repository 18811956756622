import { all, put, select, takeLatest } from 'redux-saga/effects';
import api from 'apiSingleton';
import { Main } from 'wikr-core-analytics';

// redux
import {
    disableIsLoading,
    enableIsLoading,
    getDeepLinksSuccess,
    setIsAutologinComplete,
    setIsPaid,
    setToken,
    setUserEmail,
    setUserId,
    signUp,
    signUpFail,
    signUpSuccess,
    updateSendpulseMailing,
    updateUserInfo,
    updateUserInfoFail,
} from 'redux/User/actions';
import { setCurrentWeight, setGender, setGoal, setTall, setTargetWeight } from 'redux/Onboadring/actions';
import { setCurrency as setCurrencyToStore } from 'redux/Payment/actions';
import { selectCountry } from 'redux/User/selectors';

// types
import { GET_DEEP_LINK, SET_COUNTRY, SIGN_UP, UPDATE_INFO, UPDATE_SENDPULS_MAILING } from 'redux/User/actionTypes';
import { DeepLinkData, UserInfoResponse } from 'types/user/userApiInterface';
import { GetUserResponse } from 'types/user/getUser';
import { INIT_AUTO_LOGIN } from 'redux/Onboadring/actionTypes';

// helpers
import { getParamFromUrl, isEmpty } from 'helpers/utils';

// constants
import { COUNTRIES_CURRENCY_LIST } from 'constants/countriesList';
import { PAYMENT_DEFAULT_CURRENCY } from 'constants/payments';

// services
import sentry from 'services/Sentry/SentryInstance';

function* signUpSaga({ payload: { payload, setError, toNextPage } }: ReturnType<typeof signUp>) {
    try {
        yield put(enableIsLoading());

        const data: Partial<UserInfoResponse> = yield api.user.signUp(payload);

        const { token, user_id, email } = data;

        sentry.setUser({ email, user_id });

        if (isEmpty(token)) {
            setError('basics.appError.withSupport');

            return;
        }
        yield all([put(setToken(token)), put(setUserId(user_id)), put(signUpSuccess(data)), put(setUserEmail(email))]);

        Main.customData('Lead');
        Main.customData('Registration');
        Main.customData('SIGN_UP');

        yield put(disableIsLoading());

        toNextPage();
    } catch (e) {
        console.error('Api ERROR', e);
        yield put(signUpFail(e));
        setError(e.error || 'basics.appError.withSupport');
    }
}

function* updateUserInfoSaga({ payload }: ReturnType<typeof updateUserInfo>) {
    const { data, toNextPage, fieldError } = payload;

    yield put(enableIsLoading());

    try {
        const { email }: Partial<UserInfoResponse> = yield api.user.update(data);

        yield put(setUserEmail(email));

        Main.customData('registration_page__click');

        yield put(disableIsLoading());

        toNextPage();
    } catch ({ error }) {
        yield put(updateUserInfoFail(error));
        fieldError(error);
    }
}

function* getDeepLinksSaga() {
    yield put(enableIsLoading());

    try {
        const link: DeepLinkData = yield api.user.deepLinks();

        yield put(getDeepLinksSuccess(link));
    } catch (error) {
        yield put(disableIsLoading());
        console.error('Api ERROR', error);
    }
}

function* setUserProfileFromEmail() {
    const token = getParamFromUrl('token');
    if (!token) {
        yield put(setIsAutologinComplete(true));

        return;
    }

    try {
        const localization = window.location.pathname.split('/')[1];

        localStorage.setItem('language', localization);

        yield put(setToken(token));

        const {
            email,
            user_id,
            goal,
            gender,
            is_paid,
            height,
            units,
            weight,
            target_weight,
        }: GetUserResponse = yield api.user.getUser();

        sentry.setUser({ email, user_id });

        yield all([
            put(setUserEmail(email)),
            put(setUserId(user_id)),
            put(setIsAutologinComplete(true)),
            put(setGoal(goal)),
            put(setGender(gender)),
            put(setIsPaid(is_paid)),
            put(setTall({ value: height, unit: units })),
            put(setCurrentWeight({ value: weight, unit: units })),
            put(setTargetWeight({ value: target_weight, unit: units })),
        ]);
    } catch (e) {
        console.error(e);
        yield put(setIsAutologinComplete(true));
    }
}

function* setCurrency() {
    const userCountry: string = yield select(selectCountry);

    const filteredCountry = COUNTRIES_CURRENCY_LIST.filter(({ code }) => code === userCountry);

    if (userCountry) {
        // check if country included to config list
        if (filteredCountry.length) {
            const { currency } = filteredCountry.find((item) => item.currency);

            yield put(setCurrencyToStore(currency));
        }
    }

    if (!userCountry || !filteredCountry.length) {
        yield put(setCurrencyToStore(PAYMENT_DEFAULT_CURRENCY));
    }
}

function* updateSendpulseMailingSaga({ payload }: ReturnType<typeof updateSendpulseMailing>) {
    try {
        yield put(enableIsLoading());

        const { data, nextPage } = payload;

        yield api.user.updateSendPulse(data);

        const emailUpdateEvent = data?.sendpulse_mailing_active ? 'email_updates_on' : 'email_updates_off';

        Main.customData(emailUpdateEvent, {});

        yield put(disableIsLoading());

        nextPage();
    } catch (e) {
        yield put(disableIsLoading());
        console.error(e);
    }
}

export default [
    takeLatest(SIGN_UP, signUpSaga),
    takeLatest(UPDATE_INFO, updateUserInfoSaga),
    takeLatest(GET_DEEP_LINK, getDeepLinksSaga),
    takeLatest(INIT_AUTO_LOGIN, setUserProfileFromEmail),
    takeLatest(SET_COUNTRY, setCurrency),
    takeLatest(UPDATE_SENDPULS_MAILING, updateSendpulseMailingSaga),
];
