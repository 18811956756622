import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// services
import 'services/i18n';
import Sentry from 'services/Sentry/SentryInstance';

import ErrorBoundary from 'modules/ErrorHandler';

import configureStore from './configureStore';
import App from './App';

import './styles.css';

const { store, persistor } = configureStore();
Sentry.init();

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <ErrorBoundary>
                <App />
            </ErrorBoundary>
        </PersistGate>
    </Provider>,
    document.getElementById('app')
);
