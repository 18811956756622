import * as actionTypes from './actionTypes';

// interfaces
import { OnboardingScreensList, MeasurePayload, CURRENT_BODY } from 'types/onboarding/onboardingStore';
import { IGender } from 'types/commonInterfaces';

export function setUrlParams(url: { [key: string]: string }) {
    return {
        type: actionTypes.SET_URL_PARAMS,
        payload: url,
    } as const;
}

export function setGender(token: IGender) {
    return {
        type: actionTypes.SET_GENDER,
        payload: token,
    } as const;
}

export function setGoal(token: string) {
    return {
        type: actionTypes.SET_GOAL,
        payload: token,
    } as const;
}

export function setSleepDuration(token: string) {
    return {
        type: actionTypes.SET_SLEEP_DURATION,
        payload: token,
    } as const;
}

export function setCurrentBody(body_type: CURRENT_BODY) {
    return {
        type: actionTypes.SET_CURRENT_BODY,
        payload: body_type,
    } as const;
}

export function setCurrentWeight(current_weight_with_unit: MeasurePayload) {
    return {
        type: actionTypes.SET_CURRENT_WEIGHT,
        payload: current_weight_with_unit,
    } as const;
}

export function setTargetBody(body_type: string) {
    return {
        type: actionTypes.SET_TARGET_BODY,
        payload: body_type,
    } as const;
}

export function setTargetZone(targetZones: string[]) {
    return {
        type: actionTypes.SET_TARGET_ZONE,
        payload: targetZones,
    } as const;
}

export function setTargetWeight(weight_with_unit: MeasurePayload) {
    return {
        type: actionTypes.SET_TARGET_WEIGHT,
        payload: weight_with_unit,
    } as const;
}

export function setActivityLvl(activityLevel: string) {
    return {
        type: actionTypes.SET_ACTIVITY_LVL,
        payload: activityLevel,
    } as const;
}

export function setMeasure(system: string) {
    return {
        type: actionTypes.SET_MEASURE_SYSTEM,
        payload: system,
    } as const;
}

export function setAge(age: string | number) {
    return {
        type: actionTypes.SET_AGE,
        payload: age,
    } as const;
}

export function setTall(tall: MeasurePayload) {
    return {
        type: actionTypes.SET_TALL,
        payload: tall,
    } as const;
}

export function setOnboardingScreensList(screensList: OnboardingScreensList) {
    return {
        type: actionTypes.SET_ONBOARDING_SCREENS_LIST,
        payload: screensList,
    } as const;
}

export function initAutoLogin() {
    return {
        type: actionTypes.INIT_AUTO_LOGIN,
    } as const;
}

export function setOccasion(occasion: string) {
    return {
        type: actionTypes.SET_OCCASION,
        payload: occasion,
    } as const;
}

export function setDiet(diet: string) {
    return {
        type: actionTypes.SET_DIET,
        payload: diet,
    } as const;
}

export function setWorkoutLvl(workoutLvl: string) {
    return {
        type: actionTypes.SET_WORKOUT_LVL,
        payload: workoutLvl,
    } as const;
}
