// TODO: Our Container needs to be rewrited...
import React from 'react';
import classNames from 'classnames/bind';

// styles
import styles from './Container.module.css';
import base from 'assets/styles/Common.module.css';

const globalStyles = { ...styles, ...base };
const cx = classNames.bind(globalStyles);

function Container(props) {
    const {
        globalClassNames,
        children,
        full,
        flexContainer,
        noBottomPadding,
        width368,
        justifyCenter,
        grayBG,
        solo,
        isOnePrice,
        footer,
    } = props;

    const classes = globalClassNames.map((item) => ({ [item]: globalStyles[item] }));

    const containerClass = cx(
        {
            default: true,
            withoutHeader: solo,
            fullPadding: full,
            noMargin: isOnePrice,
            flexContainer,
            noBottomPadding,
            width368,
            justifyCenter,
            grayBG,
        },
        classes
    );
    const windowHeight = window.innerHeight;
    const footerHeight = 80;

    const setHeight = () => {
        return { minHeight: !isOnePrice && windowHeight - (footer ? footerHeight : 0) + 'px' };
    };

    return (
        <div style={setHeight()} className={containerClass}>
            {children}
        </div>
    );
}

Container.defaultProps = {
    className: '',
    globalClassNames: [],
};

export default Container;
