import { all } from 'redux-saga/effects';

import { initSolidPayment } from './payment/initSolid';
import { initBankCard } from './payment/initBankCard';
import { initPayPal } from './payment/initPayPal';
import { validatePayment } from './payment/validatePayment';
import { initOneClickPayment } from './payment/oneClickPayment';
import userSagas from './user/userSagas';
import { frontChat } from './user/frontChat';

export default function* rootSaga() {
    yield all([
        ...initSolidPayment,
        ...initBankCard,
        ...initPayPal,
        ...validatePayment,
        ...initOneClickPayment,
        ...userSagas,
        ...frontChat,
    ]);
}
