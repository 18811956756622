import { Severity } from '@sentry/react';

const ERROR_LEVELS = {
    CRITICAL: Severity.Critical,
    ERROR: Severity.Error,
    WARNING: Severity.Warning,
};

const SENTRY_PAYMENT = 'PAYMENTS';
const SENTRY_TESTANIA = 'TESTANIA';
const SENTRY_ANALYTIC = 'ANALYTIC';
const SENTRY_AXIOS = 'APP_AXIOS';
const SENTRY_APP = 'APP';
const BLACK_LIST_ERROR_NAMES = ['InvalidSignatureException', 'totalRetryDelay'];

export {
    SENTRY_AXIOS,
    SENTRY_APP,
    SENTRY_PAYMENT,
    SENTRY_TESTANIA,
    SENTRY_ANALYTIC,
    ERROR_LEVELS,
    BLACK_LIST_ERROR_NAMES,
};
