import { takeLatest, put } from 'redux-saga/effects';
import Solid from 'solid-payment';
import { InitPayPal } from 'solid-payment/src/interfaces/payment';

// types
import * as actionTypes from 'redux/Payment/actionTypes';

// interfaces
import { ActionType } from 'types/commonInterfaces';
import { PaymentData } from 'types/payments/paymentApiInterfaces';

// actions
import { setInitPaymentData, setOrderId } from 'redux/Payment/actions';

function* init({ payload }: ActionType<InitPayPal>) {
    yield put({ type: actionTypes.SET_LOADING_STATUS, payload: true });

    try {
        const response: PaymentData = yield Solid.init('payPal', payload);

        yield put(setInitPaymentData(response));
        yield put(setOrderId(response?.order?.order_id));
    } catch (e) {
        console.log('Error init');
    }
}

export const initPayPal = [takeLatest(actionTypes.INIT_PAY_PAL, init)];
