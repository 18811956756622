export const onboardingScreensList = [
    'ob-goal',
    'ob-body-type',
    'ob-body-type-target',
    'ob-target-zones',
    'ob-body-image',
    'ob-activity-level',
    'ob-walking',
    'ob-pushups-squats',
    'ob-yoga-level',
    'ob-bending-knees',
    'ob-meals-feel',
    'ob-sleep',
    'ob-water',
    'ob-diets',
    'ob-height',
    'ob-weight',
    'ob-weight-target',
    'ob-age',
    'ob-tags-a',
    'ob-tags-b',
    'ob-occasion',
    'ob-occasion-result',
];

export const DEFAULT_EMPTY_URL_PARAMS = { data: 'empty_url_store' };
