import { useTranslation } from 'react-i18next';
import { uaParser } from 'wikr-core-analytics';

import config from 'config';
import { TESTANIA_DEFAULT } from 'constants/versions';
import {
    DAYS_PER_ELEVEN_MONTH,
    DAYS_PER_TEN_MONTH,
    DAYS_PER_NINE_MONTH,
    DAYS_PER_EIGHT_MONTH,
    DAYS_PER_FIVE_MONTH,
    DAYS_PER_FOUR_MONTH,
    DAYS_PER_MONTH,
    DAYS_PER_SEVEN_MONTH,
    DAYS_PER_SIX_MONTH,
    DAYS_PER_THREE_MONTH,
    DAYS_PER_TWO_MONTH,
    DAYS_PER_WEEK,
    DAYS_PER_YEAR,
    PAYMENT_METHOD_NAME,
    PAYMENT_TYPES,
} from 'constants/payments';

// constants
import { DEFAULT_LANGUAGE } from 'constants/defaultLanguage';

import lazyWithRetry from 'helpers/lazyWithRetry';

import Convert from './Convert';

const WIDTH_SIZE = 2000;
const HEIGHT_SIZE = 2000;

const convertUnits = new Convert();
const UAParser = new uaParser();

const KEYS_TO_REMOVE = [
    'currentBranchName',
    'token',
    'loginStatus',
    'paidStatus',
    'config',
    'completedOBs',
    'paymentType',
    'page-is-already-reload',
    'persist:root',
    'release_date',
    'uuid',
    'testania_name',
    'pusherTransportTLS',
    'testaniaResponseStatus',
];

export const getKgWeight = (data) => {
    const { unit, value } = data;

    return unit === 'lbs' ? convertUnits.fromLbsToKg(value) : value;
};

export const concatUrlParams = (urlParams) => {
    return Object.entries(urlParams)
        .map((item) => `&${item[0]}=${item[1]}`)
        .join('');
};

export const getNumberSystem = (unit) => {
    const mapItems = {
        lbs: 'IMPERIAL',
        in: 'IMPERIAL',
        cm: 'METRIC',
        kg: 'METRIC',
    };

    return mapItems[unit] || 'IMPERIAL';
};

export const normalizeDate = (countOfWeek, paymentType = '') => {
    const oneYearInWeeks = 12 * 4;
    const numberCountOfWeek = +countOfWeek;

    if (numberCountOfWeek < 4) {
        return getTranslatedDate(numberCountOfWeek, 'Week', paymentType);
    } else if (numberCountOfWeek < oneYearInWeeks) {
        return getTranslatedDate(Math.round(numberCountOfWeek / 4), 'Month', paymentType);
    } else {
        return getTranslatedDate(Math.round(numberCountOfWeek / oneYearInWeeks), 'Year', paymentType);
    }
};

export const deleteSlash = (word) => word.replace(/\//g, '');

export const isLifetime = (paymentType) => paymentType === 'lifetime';

export const cleanObject = (object) => {
    for (let propName in object) {
        if (object[propName] === null || object[propName] === undefined) {
            delete object[propName];
        }
    }

    return object;
};

const getTranslatedDate = (value, type, paymentType) => {
    const { t } = useTranslation();

    const pluralStatement =
        value >= 2 && value <= 4
            ? t(`pluralSecondForm${type}${paymentType}`, { count: value })
            : t(`plural${type}${paymentType}`, { count: value });

    return value === 1 ? t(`single${type}${paymentType}`, { count: value }) : pluralStatement;
};

export const getRelease = () => {
    return JSON.parse(config.RELEASE);
};

export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    return parts.length === 2 ? parts.pop().split(';').shift() : null;
};

export const isFirstMirror = () => {
    return window.location.host === config.FIRST_MIRROR_DOMAIN_NAME || '';
};

// todo add real domain
export const getProjectEmail = () => 'support@yoga-go.io';

export const checkReviewer = () => {
    return JSON.parse(localStorage.getItem('testania_client_version')) === TESTANIA_DEFAULT;
};

export const getPaymentNameByMethod = (paymentMethod) => {
    try {
        return PAYMENT_METHOD_NAME[paymentMethod];
    } catch (e) {
        console.error(e);
        throw Error(`no payment method founded in PAYMENT_METHOD_NAME with name ${paymentMethod}`);
    }
};

export const getPaymentIdByMethod = (paymentMethod) => {
    try {
        return PAYMENT_TYPES[paymentMethod];
    } catch (e) {
        console.error(e);
        throw Error(`no payment method founded in PAYMENT_TYPES with name ${paymentMethod}`);
    }
};

export const joinClasses = (...arg) => {
    return [...arg].join(' ');
};

export const replaceUnderscore = (string) => string.replace(/_/g, '-');

export const generatePolicyLink = (mirror, review, link) => {
    const checkIsReviewer = checkReviewer() ? review : link;

    return isFirstMirror() ? mirror : checkIsReviewer;
};

export const fromPennyToInt = (penny) => penny / 100;

export const getPixelAnalyticID = () => (isAttributionFlow() ? config.PIXEL_FB_ID : config.PIXEL_ID);

export const isAttributionFlow = () => {
    const attributionsList = ['attribution1', 'attribution2', 'attribution3', 'attribution4'];
    const pathname = deleteSlash(window.location.pathname);
    const urlParams = JSON.parse(localStorage.getItem('urlParams'));
    const pageType = urlParams?.page_type;

    return attributionsList.includes(pathname) || pageType === 'attribution';
};

export const getDeviceOS = () => {
    const userAgent = window.navigator.userAgent;
    const getOSData = UAParser.setUA(userAgent).getOS();

    return getOSData?.name?.toUpperCase() || 'DEFAULT_DEVICE';
};

export const scrollToTop = () => {
    window.scrollTo(0, 0);
};

export const updateGlobalLoaderVisibility = (isVisible) => {
    const globalLoader = document.getElementById('app-loader');

    globalLoader.style.display = isVisible ? 'block' : 'none';
};

export const getFaviconElement = (id = 'favicon') => {
    return document.getElementById(id);
};

export const getParamFromUrl = (paramName) => {
    const params = new URLSearchParams(window.location.search);

    return params.get(paramName);
};

export const periodNormalizer = (period) => {
    if (period === DAYS_PER_YEAR) {
        return '1 Year';
    } else if (period === DAYS_PER_WEEK) {
        return '1 Week';
    } else if (period === DAYS_PER_MONTH) {
        return '1 Month';
    } else if (period === DAYS_PER_TWO_MONTH) {
        return '2 Months';
    } else if (period === DAYS_PER_THREE_MONTH) {
        return '3 Months';
    } else if (period === DAYS_PER_FOUR_MONTH) {
        return '4 Months';
    } else if (period === DAYS_PER_FIVE_MONTH) {
        return '5 Months';
    } else if (period === DAYS_PER_SIX_MONTH) {
        return '6 Months';
    } else if (period === DAYS_PER_SEVEN_MONTH) {
        return '7 Months';
    } else if (period === DAYS_PER_EIGHT_MONTH) {
        return '8 Months';
    }

    return '0_null'; // fix broken build
};

export const dateNormalizer = (days) => {
    if (days === DAYS_PER_YEAR) {
        return '1_year plan';
    } else if (days === DAYS_PER_MONTH) {
        return '1_month plan';
    } else if (days === DAYS_PER_TWO_MONTH) {
        return '2_month plan';
    } else if (days === DAYS_PER_THREE_MONTH) {
        return '3_month plan';
    } else if (days === DAYS_PER_FOUR_MONTH) {
        return '4_month plan';
    } else if (days === DAYS_PER_FIVE_MONTH) {
        return '5_month plan';
    } else if (days === DAYS_PER_SIX_MONTH) {
        return '6_month plan';
    } else if (days === DAYS_PER_SEVEN_MONTH) {
        return '7_month plan';
    } else if (days === DAYS_PER_EIGHT_MONTH) {
        return '8_month plan';
    } else if (days === DAYS_PER_WEEK) {
        return '1_week plan';
    }

    return '0_null'; // fix broken build
};

export const getSubscriptionTitle = (product, isTrial) => {
    const period = typeof product === 'object' ? product.period : product;

    const TRIAL_PERIODS_ARR = [
        { period: 1, count: 1, context: 'day' },
        { period: 2, count: 2, context: 'day' },
        { period: 3, count: 3, context: 'day' },
        { period: 4, count: 4, context: 'day' },
        { period: 5, count: 5, context: 'day' },
        { period: 6, count: 6, context: 'day' },
        { period: 7, count: 7, context: 'day' },
        { period: 8, count: 8, context: 'day' },
        { period: 9, count: 9, context: 'day' },
        { period: 10, count: 10, context: 'day' },
        { period: 11, count: 11, context: 'day' },
        { period: 12, count: 12, context: 'day' },
        { period: 13, count: 13, context: 'day' },
        { period: 14, count: 14, context: 'day' },
        { period: DAYS_PER_MONTH, count: 1, context: 'month' },
    ];

    const PERIODS_ARR = [
        { period: DAYS_PER_WEEK, count: 1, context: 'week' },
        { period: DAYS_PER_MONTH, count: 1, context: 'month' },
        { period: DAYS_PER_TWO_MONTH, count: 2, context: 'month' },
        { period: DAYS_PER_THREE_MONTH, count: 3, context: 'month' },
        { period: DAYS_PER_FOUR_MONTH, count: 4, context: 'month' },
        { period: DAYS_PER_FIVE_MONTH, count: 5, context: 'month' },
        { period: DAYS_PER_SIX_MONTH, count: 6, context: 'month' },
        { period: DAYS_PER_SEVEN_MONTH, count: 7, context: 'month' },
        { period: DAYS_PER_EIGHT_MONTH, count: 8, context: 'month' },
        { period: DAYS_PER_NINE_MONTH, count: 9, context: 'month' },
        { period: DAYS_PER_TEN_MONTH, count: 10, context: 'month' },
        { period: DAYS_PER_ELEVEN_MONTH, count: 11, context: 'month' },
        { period: DAYS_PER_YEAR, count: 1, context: 'year' },
    ];

    const PERIODS = isTrial ? TRIAL_PERIODS_ARR : PERIODS_ARR;

    if (period) {
        const currentPeriod = PERIODS.find((item) => item.period === period);

        return { count: currentPeriod?.count || 'NULL', context: currentPeriod?.context || 'NULL' };
    }

    return { count: 'NULL', context: 'NULL' };
};

export const parsePaymentType = (period) => dateNormalizer(period).replace('_', '-');

export const getProductPaymentData = (currentProduct) => {
    const { trial, period, start_price, price } = currentProduct;

    const currentPaymentType = getSubscriptionTitle(trial);
    const paymentType = trial === period ? 'SEVERAL_MONTH_PLAN' : 'ONE_WEEK_PLAN';
    const paymentLabel = getSubscriptionTitle(period);
    const fullPrice = getPriceFromCents(start_price);
    const trialPrice = getPriceFromCents(price);
    const trialLabel = '7-day trial';

    return { paymentType, currentPaymentType, paymentLabel, fullPrice, trialPrice, trialLabel };
};

export const getDiscount = (oldPrice, newPrice) => {
    return (((oldPrice - newPrice) / oldPrice) * 100).toFixed();
};

export const getMaxElemFromArr = (arr, fieldName) => {
    return arr.reduce((prev, cur) => (prev[fieldName] > cur[fieldName] ? prev : cur));
};

export const getPriceFromCents = (price) => {
    const priceValue = Number(price);

    return getToFixedNumber(priceValue / 100);
};

export const getDynamicallyDiscount = ({ products, selectedPeriod, currentPrice }) => {
    const productsByPeriod = products.filter((product) => product.trial === selectedPeriod);
    const maxElemFromArr = getMaxElemFromArr(productsByPeriod, 'price');
    const introductoryPriceMax = maxElemFromArr?.price;

    return Math.round((1 - currentPrice / introductoryPriceMax) * 100);
};

export const ageToDate = (age) => {
    const today = new Date();

    return `${today.getFullYear() - Number(age)}-01-01`;
};

export function lazyWithPreload(factory) {
    const Component = lazyWithRetry(factory);
    Component.preload = factory;

    return Component;
}

// TODO: refactor
export const getToFixedNumber = (number, numbersAfterComma = 2) => {
    const numberValue = Number(number);

    return Number(numberValue.toFixed(numbersAfterComma));
};

export const isEmpty = (value) => {
    return (
        value === null ||
        value === undefined ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0)
    );
};

export const isString = (value) => typeof value.valueOf() === 'string';

export const replaceToUnderscore = (screen) => {
    return screen.replace(/-/g, '_');
};

export const getOnlyPositiveNumber = (digit) => {
    return digit <= 0 ? 1 : digit;
};

export const enableOnlyDigitsInput = (event) => {
    if (event.key === 'Tab' || event.key === 'Backspace') {
        return;
    }
    if (event.key < '0' || event.key > '9') {
        event.preventDefault();
    }
};

export const disableInputMoreThatMaxFieldLength = (element) => {
    // requires element's maxLength attribute defined
    if (element.value.length > element.maxLength) {
        element.value = element.value.slice(0, element.maxLength);
    }
};

export const getTrimmedFields = (fields) => {
    Object.keys(fields).forEach((k) => {
        if (typeof fields[k] === 'string') {
            fields[k] = fields[k].trim();
        }
    });

    return fields;
};

export const isShortPayment = () => {
    const payment_flow = JSON.parse(localStorage.getItem('config'))?.payment_flow;

    if (isEmpty(payment_flow)) {
        return false;
    }

    const id = payment_flow?.find(({ parent_id }) => parent_id === null)?.id;

    return payment_flow?.some(({ parent_id, is_paid }) => parent_id === id && !is_paid);
};

export const getDiscountForPayment = (currentProduct, pageInfo) => {
    const selectedPeriod = currentProduct?.trial;
    const currentPrice = currentProduct?.price;

    return getDynamicallyDiscount({ products: pageInfo?.products, selectedPeriod, currentPrice });
};

export const generateQueryParams = () => {
    const params = JSON.parse(localStorage.getItem('urlParams')) || {};
    const searchParams = new URLSearchParams();

    Object.keys(params).forEach((key) => searchParams.append(key, params[key]));

    return searchParams.toString();
};

const fromEntriesPolyfill = (iterable) => {
    return [...iterable].reduce((obj, [key, val]) => ({ ...obj, [key]: val }), {});
};

export const getUrlParams = () => {
    const urlSearchParams = new URLSearchParams(window.location.search)?.entries();

    const params = fromEntriesPolyfill(urlSearchParams);

    return isEmpty(params) ? null : params;
};

export const translate = (key) => {
    const { t } = useTranslation();

    return t(key);
};

export const getWindowSize = (params = '(min-width: 1024px)') => {
    const mediaQuery = window.matchMedia(params);

    return mediaQuery.matches;
};

export const getLanguage = () => localStorage.getItem('language') || DEFAULT_LANGUAGE;

export const deleteConfig = () => {
    KEYS_TO_REMOVE.forEach((key) => localStorage.removeItem(key));

    localStorage.setItem('isResetStore', 'true');
};

export const checkFbBots = () => {
    const windowWidth = window.screen.width;
    const windowHeight = window.screen.height;

    return windowWidth === WIDTH_SIZE && windowHeight === HEIGHT_SIZE;
};
