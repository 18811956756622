import config from 'config';

// constants
import { DEFAULT_FLOW, DEFAULT_FLOW_PROJECT_NAME, DEFAULT_EXPIRE_CONFIG_TIME } from 'constants/defaultFlows';

// helpers
import { getConfigBy } from './helpers';

export const TESTANIA_INIT_CONFIG = {
    project: DEFAULT_FLOW_PROJECT_NAME,
    testName: getConfigBy('test-name', 'default'),
    branchName: getConfigBy('branch-name', 'default'),
    defaultBranches: ['default'],
    defaultFlow: [DEFAULT_FLOW],
    testaniaUrl: config.TESTANIA_HOST,
    testaniaApiKey: config.TESTANIA_X_API_KEY,
    expireConfigTime: DEFAULT_EXPIRE_CONFIG_TIME,
};
