// config
import config from '../config';

// api
import ApiClient from './apiClient';
import UserApi from './components/User';
import PaymentApi from './components/Payment';

// helpers
import { checkReviewer } from 'helpers/utils';

const TOKEN_KEY = 'token';

export default function () {
    const apiClient = new ApiClient({ apiUrl: config.API_URL, isSandbox: checkReviewer() });
    const user = new UserApi({ apiClient });
    const payment = new PaymentApi({ apiClient });

    window.addEventListener('storage', function (storageEvent) {
        if (TOKEN_KEY === storageEvent.key) {
            const { newValue: token } = storageEvent;

            apiClient.private.setAuthToken(token);
            user.apiClient.private.setAuthToken(token);
            payment.apiClient.private.setAuthToken(token);
        }
    });

    return {
        apiClient,
        user,
        payment,
    };
}
