import Base from './Base';

// interfaces
import { ValidateData, UpsaleData, PaymentData } from 'types/payments/paymentApiInterfaces';
import { ValidateResponse } from 'types/payments/validate';

class UserApi extends Base {
    async validate<Data = ValidateData, Response = ValidateResponse>(payload: Data): Promise<Response | unknown> {
        return await this.apiClient.private.post<Data, Response>('payments/validate', payload);
    }

    async upSale<Data = UpsaleData, Response = PaymentData>(payload: Data): Promise<Partial<Response> | unknown> {
        return await this.apiClient.private.post<Data, Response>('payments/recurring', payload);
    }
}

export default UserApi;
