import * as actionTypes from './actionTypes';

// interfaces
import { TestaniaStore } from 'types/testania/testaniaStore';
import { InferValueTypes } from 'types/commonInterfaces';

// actions
import * as action from './actions';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const initialState: TestaniaStore = {
    onboardingConfig: [],
    testaniaName: null,
    testaniaLoginStatus: null,
    completeOBs: [],
    currantBranchName: null,
    flowLink: null,
};

const TestaniaReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case actionTypes.SET_ONBOARDING_CONFIG:
            return { ...state, onboardingConfig: action.payload };

        case actionTypes.SET_TESTANIA_NAME:
            localStorage.setItem('testania_name', action.payload);

            return { ...state, testaniaName: action.payload };

        case actionTypes.SET_TESTANIA_LOGIN_STATUS:
            localStorage.setItem('loginStatus', `${action.payload}`);

            return { ...state, testaniaLoginStatus: action.payload };

        case actionTypes.SET_COMPLETE_OBS:
            localStorage.setItem('completedOBs', `${action.payload}`);

            return { ...state, completeOBs: [...state.completeOBs, action.payload] };

        case actionTypes.SET_CURRENT_BRANCH_NAME:
            localStorage.setItem('currentBranchName', action.payload);

            return { ...state, currantBranchName: action.payload };
        case actionTypes.SET_FLOW_LINK:
            return { ...state, flowLink: action.payload };

        default:
            return state;
    }
};

export default TestaniaReducer;
