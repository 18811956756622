import Solid from 'solid-payment';
import { takeLatest, put, all } from 'redux-saga/effects';
import { InitBankCard } from 'solid-payment/src/interfaces/payment';

// types
import * as actionTypes from 'redux/Payment/actionTypes';

// actions
import { setBankCardSrc, setInitPaymentData, setOrderId } from 'redux/Payment/actions';

// interfaces
import { ActionType } from 'types/commonInterfaces';
import { PaymentData } from 'types/payments/paymentApiInterfaces';

function* init({ payload }: ActionType<InitBankCard>) {
    try {
        yield put({ type: actionTypes.SET_LOADING_STATUS, payload: true });
        const response: PaymentData = yield Solid.init('bankCard', payload);

        yield all([
            put(setBankCardSrc(response?.pay_form?.form_url)),
            put({ type: actionTypes.SET_LOADING_STATUS, payload: false }),
            put(setInitPaymentData(response)),
            put(setOrderId(response?.order?.order_id)),
        ]);
    } catch (e) {
        console.log('Error');
    }
}

export const initBankCard = [takeLatest(actionTypes.INIT_BANK_CARD, init)];
