export const DEFAULT_FLOW_PROJECT_NAME = 'welltech_store';
export const DEFAULT_EXPIRE_CONFIG_TIME = 1440;

export const DEFAULT_FLOW = {
    branch_name: 'default',
    flow_name: 'welltech_store__default__default__default',
    start_page: { id: 'start_gender', name: 'start_gender', config: {} },
    onboarding_flow: [
        { id: 'ob_social_proof', name: 'ob_social_proof', config: {} },
        {
            id: 'ob_goal',
            name: 'ob_goal',
            config: {},
        },
        { id: 'ob_body_type', name: 'ob_body_type', config: {} },
        {
            id: 'ob_body_type_target',
            name: 'ob_body_type_target',
            config: {},
        },
        { id: 'ob_target_zones', name: 'ob_target_zones', config: {} },
        {
            id: 'ob_body_image',
            name: 'ob_body_image',
            config: {},
        },
        {
            id: 'ob_activity_level',
            name: 'ob_activity_level',
            config: {},
        },
        { id: 'ob_workout', name: 'ob_workout', config: {} },
        { id: 'ob_stairs', name: 'ob_stairs', config: {} },
        { id: 'ob_walking', name: 'ob_walking', config: {} },
        {
            id: 'ob_pushups_squats',
            name: 'ob_pushups_squats',
            config: {},
        },
        { id: 'ob_meals_feel', name: 'ob_meals_feel', config: {} },
        {
            id: 'ob_sleep',
            name: 'ob_sleep',
            config: {},
        },
        {
            id: 'ob_water',
            name: 'ob_water',
            config: {},
        },
        { id: 'ob_diets', name: 'ob_diets', config: {} },
        { id: 'ob_height', name: 'ob_height', config: {} },
        {
            id: 'ob_weight',
            name: 'ob_weight',
            config: {},
        },
        { id: 'ob_weight_target', name: 'ob_weight_target', config: {} },
        { id: 'ob_age', name: 'ob_age', config: {} },
        { id: 'feedback_summary_fitlevel', name: 'feedback_summary_fitlevel', config: {} },
        { id: 'ob_occasion', name: 'ob_occasion', config: {} },
        { id: 'ob_occasion_result', name: 'ob_occasion_result', config: {} },
        { id: 'creating', name: 'creating', config: {} },
        { id: 'email', name: 'email', config: {} },
        {
            id: 'email_consent',
            name: 'email_consent',
            config: {},
        },
        { id: 'result', name: 'result', config: {} },
    ],
    payment_flow: [
        {
            id: 'payment_g1_foxtrot1',
            name: 'payment_g1_foxtrot1',
            parent_id: 'payment_g1_foxtrot1',
            is_paid: false,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'subscription',
                    id: 'abc42fab-2363-49ea-a12a-c7232f018c67',
                    price: 499,
                    name: 'One-week intro (4.99) + One-week (10.43)',
                    trial: 31,
                    period: 31,
                    price_per_state: 71,
                    start_price_per_state: 149,
                    is_preselected: false,
                    start_price: 1043,
                    product_state: 'per_day',
                    ltv: 2716,
                },
                {
                    payment_type: 'subscription',
                    id: 'c5681005-1f02-4cdd-95da-2d0e778f7a0b',
                    price: 1399,
                    name: 'One-month intro (13.99) + One-month (29.84)',
                    trial: 31,
                    period: 31,
                    price_per_state: 45,
                    start_price_per_state: 96,
                    is_preselected: false,
                    start_price: 2984,
                    product_state: 'per_day',
                    ltv: 5405,
                },
                {
                    payment_type: 'subscription',
                    id: '9db14fb3-f224-41f6-9cc3-fa6e0d1be0c7',
                    price: 2199,
                    name: 'Three-month intro (21.99) + Three-month (44.76)',
                    trial: 31,
                    period: 31,
                    price_per_state: 24,
                    start_price_per_state: 49,
                    is_preselected: false,
                    start_price: 4476,
                    product_state: 'per_day',
                    ltv: 3861,
                },
                {
                    payment_type: 'subscription',
                    id: '02b24a70-509e-45f3-97b2-f1fcd8cdf878',
                    price: 1043,
                    name: 'One-week intro (10.43) + One-month (29.84)',
                    trial: 60,
                    period: 60,
                    price_per_state: 149,
                    start_price_per_state: 0,
                    is_preselected: false,
                    start_price: 2984,
                    product_state: 'per_day',
                    ltv: 6136,
                },
                {
                    payment_type: 'subscription',
                    id: '02b24a70-509e-45f3-97b2-f1fcd8cdf878',
                    price: 2984,
                    name: 'One-month intro (29.84) + One-month (29.84)',
                    trial: 60,
                    period: 60,
                    price_per_state: 96,
                    start_price_per_state: 0,
                    is_preselected: false,
                    start_price: 2984,
                    product_state: 'per_day',
                    ltv: 7860,
                },
                {
                    payment_type: 'subscription',
                    id: '31caa3cb-26fe-41a8-9921-3461b2c8c91e',
                    price: 4476,
                    name: 'Three-month intro (44.76) + Three-month (44.76)',
                    trial: 60,
                    period: 60,
                    price_per_state: 49,
                    start_price_per_state: 0,
                    is_preselected: false,
                    start_price: 4476,
                    product_state: 'per_day',
                    ltv: 5895,
                },
                {
                    payment_type: 'subscription',
                    id: '226e51e5-66a9-4c2d-bc7c-afb88c0814c7',
                    price: 1043,
                    name: 'One-week intro (10.43) + One-month (29.84)',
                    trial: 90,
                    period: 90,
                    price_per_state: 149,
                    start_price_per_state: 0,
                    is_preselected: false,
                    start_price: 2984,
                    product_state: 'per_day',
                    ltv: 6136,
                },
                {
                    payment_type: 'subscription',
                    id: '4955828c-1691-4c50-825f-01f89d3c7d8e',
                    price: 2984,
                    name: 'One-month intro (29.84) + One-month (29.84)',
                    trial: 90,
                    period: 90,
                    price_per_state: 96,
                    start_price_per_state: 0,
                    is_preselected: false,
                    start_price: 2984,
                    product_state: 'per_day',
                    ltv: 7860,
                },
                {
                    payment_type: 'subscription',
                    id: '31caa3cb-26fe-41a8-9921-3461b2c8c91e',
                    price: 4476,
                    name: 'Three-month intro (44.76) + Three-month (44.76)',
                    trial: 90,
                    period: 90,
                    price_per_state: 49,
                    start_price_per_state: 0,
                    is_preselected: false,
                    start_price: 4476,
                    product_state: 'per_day',
                    ltv: 5895,
                },
            ],
            config: {},
        },
        {
            id: 'payment_g1_foxtrot1',
            name: 'payment_g1_foxtrot1',
            parent_id: null,
            is_paid: null,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'subscription',
                    id: 'c5681005-1f02-4cdd-95da-2d0e778f7a0b',
                    price: 699,
                    name: 'One-week intro (6.99) + One-week (10.43)',
                    trial: 7,
                    period: 7,
                    price_per_state: 100,
                    start_price_per_state: 149,
                    is_preselected: false,
                    start_price: 1043,
                    product_state: 'per_day',
                    ltv: 3805,
                },
                {
                    payment_type: 'subscription',
                    id: '226e51e5-66a9-4c2d-bc7c-afb88c0814c7',
                    price: 1999,
                    name: 'One-month intro (19.99) + One-month (29.84)',
                    trial: 31,
                    period: 31,
                    price_per_state: 64,
                    start_price_per_state: 96,

                    is_preselected: false,
                    start_price: 2984,
                    product_state: 'per_day',
                    ltv: 5265,
                },
                {
                    payment_type: 'subscription',
                    id: 'd1b582ce-3f21-4620-a833-a98da465a93f',
                    price: 2999,
                    name: 'Three-month intro (29.99) + Three-month (44.76)',
                    trial: 90,
                    period: 90,
                    price_per_state: 33,
                    start_price_per_state: 49,
                    is_preselected: false,
                    start_price: 4476,
                    product_state: 'per_day',
                    ltv: 4476,
                },
                {
                    payment_type: 'subscription',
                    id: '02b24a70-509e-45f3-97b2-f1fcd8cdf878',
                    price: 1043,
                    name: 'One-week intro (10.43) + One-month (29.84)',
                    trial: 7,
                    period: 31,
                    price_per_state: 149,
                    start_price_per_state: 0,
                    is_preselected: false,
                    start_price: 2984,
                    product_state: 'per_day',
                    ltv: 6136,
                },
                {
                    payment_type: 'subscription',
                    id: '4955828c-1691-4c50-825f-01f89d3c7d8e',
                    price: 2984,
                    name: 'One-month intro (29.84) + One-month (29.84)',
                    trial: 31,
                    period: 31,
                    price_per_state: 96,
                    start_price_per_state: 0,
                    is_preselected: false,
                    start_price: 2984,
                    product_state: 'per_day',
                    ltv: 7860,
                },
                {
                    payment_type: 'subscription',
                    id: '31caa3cb-26fe-41a8-9921-3461b2c8c91e',
                    price: 4476,
                    name: 'Three-month intro (44.76) + Three-month (44.76)',
                    trial: 90,
                    period: 90,
                    price_per_state: 49,
                    start_price_per_state: 0,
                    is_preselected: false,
                    start_price: 4476,
                    product_state: 'per_day',
                    ltv: 5895,
                },
            ],
            config: {},
        },
    ],
    expire: 1633705410444,
};
