import { createSelector } from 'reselect';

// types
import { RootState } from '../rootReducer';

const selectUIEffects = (state: RootState) => state.uiEffects;

const selectHideHeader = createSelector(selectUIEffects, (uiEffects) => uiEffects.hide_header);
const selectModalIsOpen = createSelector(selectUIEffects, (uiEffects) => uiEffects.modalIsOpen);
const selectIssueType = createSelector(selectUIEffects, (uiEffects) => uiEffects.issueType);
const selectIssueTitle = createSelector(selectUIEffects, (uiEffects) => uiEffects.issueTitle);
const selectIssueDescription = createSelector(selectUIEffects, (uiEffects) => uiEffects.issueDescription);
const selectAppLoader = createSelector(selectUIEffects, (uiEffects) => uiEffects.appLoader);
const selectHideProgressBar = createSelector(selectUIEffects, (uiEffects) => uiEffects.hideProgressBar);
const selectHidePayPal = createSelector(selectUIEffects, (uiEffects) => uiEffects.hidePayPal);
const selectInitFrontChat = createSelector(selectUIEffects, (uiEffects) => uiEffects.initFrontChat);

export {
    selectUIEffects,
    selectHideHeader,
    selectModalIsOpen,
    selectIssueType,
    selectIssueTitle,
    selectIssueDescription,
    selectAppLoader,
    selectHideProgressBar,
    selectHidePayPal,
    selectInitFrontChat,
};
