import React, { useEffect } from 'react';
import LazyLoad, { forceVisible } from 'react-lazyload';

// styles
import styles from './LazyImage.module.css';

// subComponents
import Placeholder from './Placeholder';

// types
import { ILazyImage } from './types';

function LazyImage({ height = 20, src, alt, isForceVisible = false, imgHeight }: ILazyImage) {
    const loaderHeight = { minHeight: height };
    const imageHeight = { height: imgHeight };

    useEffect(() => {
        if (isForceVisible) forceVisible();
    }, [isForceVisible]);

    return (
        <div style={loaderHeight}>
            {
                // @ts-ignore
                <LazyLoad height={height} style={loaderHeight} placeholder={<Placeholder />} debounce={10}>
                    <img src={src} alt={alt} className={styles.image} style={imageHeight} />
                </LazyLoad>
            }
        </div>
    );
}

export default LazyImage;
