import { select, takeLatest } from 'redux-saga/effects';
import api from 'apiSingleton';

// config
import config from 'config';

// selectors
import { selectPaymentMethod } from 'redux/Payment/selectors';
import { selectInitFrontChat } from 'redux/UiEffects/selectors';

// types
import { PaymentMethod } from 'interfaces/Payments/payments';
import { HashedEmailResp } from 'types/user/userApiInterface';
import { GetUserResponse } from 'types/user/getUser';
import { INIT_FRONT_CHAT } from 'redux/UiEffects/actionTypes';

// helpers
import { cleanObject, getLanguage } from 'helpers/utils';

function* frontChatInitialization() {
    const language = getLanguage();

    const shouldInitChat: boolean = yield select(selectInitFrontChat);
    const paymentMethod: PaymentMethod = yield select(selectPaymentMethod);

    try {
        if (shouldInitChat) {
            const response: HashedEmailResp = { data: yield api.user.hashedEmail() };
            const { email, name, country, is_paid: isPaid, product_list }: GetUserResponse = yield api.user.getUser();

            const customFields = cleanObject({
                platform: 'web',
                // todo add real domain
                project: config?.AWS_DELIVERY_STREAM_NAME || 'yoga-go',
                country,
                language,
                isPaid,
                paymentType: paymentMethod,
                ...product_list,
            });

            const initData = {
                chatId: config?.FRONT_CHAT_ID,
                useDefaultLauncher: true,
                email: email?.toLowerCase(),
                name: name,
                userHash: response?.data,
                customFields: customFields,
            };

            if (!window.FrontChat) {
                const insertScript = () => {
                    const d = document;
                    const script = d.createElement('script');
                    script.type = 'text/javascript';
                    script.async = true;
                    script.src = 'https://chat-assets.frontapp.com/v1/chat.bundle.js';
                    d.body.appendChild(script);
                    script.onload = () => {
                        window.FrontChat('init', initData);
                    };
                };

                insertScript();
            }
        }
        if (window.FrontChat && !shouldInitChat) {
            window.FrontChat('shutdown');
            delete window.FrontChat;
        }
    } catch (e) {
        console.error('chat error', e);
    }
}

export const frontChat = [takeLatest(INIT_FRONT_CHAT, frontChatInitialization)];
