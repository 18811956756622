export const DAYS_PER_YEAR = 365;
export const DAYS_PER_MONTH = 31;
export const DAYS_PER_WEEK = 7;
export const DAYS_PER_TWO_MONTH = 62;
export const DAYS_PER_THREE_MONTH = 90;
export const DAYS_PER_FOUR_MONTH = 121;
export const DAYS_PER_FIVE_MONTH = 152;
export const DAYS_PER_SIX_MONTH = 182;
export const DAYS_PER_SEVEN_MONTH = 212;
export const DAYS_PER_EIGHT_MONTH = 243;
export const DAYS_PER_NINE_MONTH = 273;
export const DAYS_PER_TEN_MONTH = 304;
export const DAYS_PER_ELEVEN_MONTH = 334;

export const APP_FULL_ACCESS = 'app_full_access';
export const VIP_SUPPORT = 'vip_support';

export const PAYMENT_TYPES = {
    CREDIT_CARD: 1,
    PAYPAL: 4,
    APPLE: 3,
};

export const PAYMENT_METHOD_NAME = {
    1: 'Bank_Card',
    3: 'ApplePay',
    4: 'PayPal',
};

export const PAYMENT_DEFAULT_CURRENCY = {
    sign: '$',
    name: 'USD',
};

export const EURO = {
    sign: '€',
    name: 'EUR',
};

export const GBP = {
    sign: '£',
    name: 'GBP',
};

export const DEVICE_OS = {
    ANDROID: 'ANDROID',
    IOS: 'IOS',
};

export const PAYMENT_FLOW_PERIODS = [
    DAYS_PER_MONTH,
    DAYS_PER_TWO_MONTH,
    DAYS_PER_THREE_MONTH,
    DAYS_PER_FOUR_MONTH,
    DAYS_PER_FIVE_MONTH,
    DAYS_PER_SIX_MONTH,
    DAYS_PER_SEVEN_MONTH,
    DAYS_PER_EIGHT_MONTH,
    DAYS_PER_YEAR,
    DAYS_PER_WEEK,
];

// Introductory flow
export const INTRODUCTORY_A_FLOW_PAYMENT_ID = 'payment_Aa_checkout_Alpha1';
export const INTRODUCTORY_B_FLOW_PAYMENT_ID = 'payment_Ab_Checkout_Alpha1';

export const CREDIT_CARD_LITERAL = 'CREDIT_CARD';
export const PAYPAL_LITERAL = 'PAYPAL';
