import * as actionTypes from './actionTypes';

// interfaces
import { Currency } from '../sagas/user/interfaces';
import { CurrentProduct, PaymentMethod } from 'types/payments/payments';
import { ValidatePayload } from 'types/payments/validate';
import { IInitOneClickPayment } from 'types/payments/oneClickPayment';
import { CustomData, Init } from 'types/payments/paymentSystemsInit';


/* ================= NEW PAYMENT ACTIONS*/

export const setCurrentProduct = (product: CurrentProduct) => {
    return {
        type: actionTypes.SET_CURRENT_PRODUCT,
        payload: product,
    } as const;
};

export const setTrialPrice = (trialPrice: number) => {
    return {
        type: actionTypes.SET_TRIAL_PRICE,
        payload: trialPrice,
    } as const;
};

export const initApplePay = (data: CustomData) => {
    return {
        type: actionTypes.INIT_APPLE_PAY,
        payload: data,
    } as const;
};

export const init = (data: Init) => {
    return {
        type: actionTypes.INIT_PAYMENT,
        payload: data,
    } as const;
};

export const initBankCard = (data: Init) => {
    return {
        type: actionTypes.INIT_BANK_CARD,
        payload: data,
    } as const;
};

export const initPayPal = (data: Init) => {
    return {
        type: actionTypes.INIT_PAY_PAL,
        payload: data,
    } as const;
};

export const setLoadingStatus = (status: boolean) => {
    return {
        type: actionTypes.SET_LOADING_STATUS,
        payload: status,
    } as const;
};

export const changeExclusiveOfferState = (status: boolean) => {
    return {
        type: actionTypes.SET_EXCLUSIVE_OFFER,
        payload: status,
    } as const;
};

export const validatePayment = (payload: ValidatePayload) => {
    return {
        type: actionTypes.VALIDATE_PAYMENT,
        payload: payload,
    } as const;
};

/* =================*/

export const changePaymentMethod = (type: PaymentMethod) => {
    return {
        type: actionTypes.CHANGE_PAYMENT_TYPE,
        payload: type,
    } as const;
};

export const setPaymentConfig = (payload: { [payment: string]: boolean }) => {
    return {
        type: actionTypes.SET_PAYMENT_CONFIG,
        payload: payload,
    } as const;
};

export const setCurrency = (currency: Currency) => {
    return {
        type: actionTypes.SET_PAYMENT_CURRENCY,
        payload: currency,
    } as const;
};

export const setUpsellProductPrice = (price: number) => {
    return {
        type: actionTypes.SET_UPSELL_PRODUCT_PRICE,
        payload: price,
    } as const;
};

export const setUpsellProductId = (upsellId: string) => {
    return {
        type: actionTypes.SET_UPSELL_PRODUCT_ID,
        payload: upsellId,
    } as const;
};

export const initOneClickPayment = (data: IInitOneClickPayment) => {
    return {
        type: actionTypes.INIT_ONE_CLICK_PAYMENT,
        payload: data,
    } as const;
};

export const setPaymentDiscount = (discount: number) => {
    return {
        type: actionTypes.SET_PAYMENT_DISCOUNT,
        payload: discount,
    } as const;
};

export const setIsPaymentScreen = (isPayment: boolean) => {
    return {
        type: actionTypes.SET_IS_PAYMENT_SCREEN,
        payload: isPayment,
    } as const;
};

export const setOrderId = (orderId: string) => {
    return {
        type: actionTypes.SET_ORDER_ID,
        payload: orderId,
    } as const;
};

export const setSubscriptionId = (subscriptionId: string) => {
    return {
        type: actionTypes.SET_SUBSCRIPTION_ID,
        payload: subscriptionId,
    } as const;
};

export const setSolidInited = (solidInited: boolean) => {
    return {
        type: actionTypes.INIT_SOLID_PAYMENT,
        payload: solidInited,
    } as const;
};

export const setInitPaymentData = (initPaymentData: object) => {
    return {
        type: actionTypes.SET_PAYMENT_DATA,
        payload: initPaymentData,
    } as const;
};

export const setValidatePaymentData = (validatePaymentData: object) => {
    return {
        type: actionTypes.VALIDATE_PAYMENT_DATA,
        payload: validatePaymentData,
    } as const;
};

export const setBankCardSrc = (bankCardSrc: string) => {
    return {
        type: actionTypes.SET_BANKCARD_SRC,
        payload: bankCardSrc,
    } as const;
};

export const setValidatePaymentFail = (error: object) => {
    return {
        type: actionTypes.VALIDATE_PAYMENT_FAIL,
        payload: error,
    } as const;
};

export const setPaymentType = (paymentType: string) => {
    return {
        type: actionTypes.SET_PAYMENT_TYPE,
        payload: paymentType,
    } as const;
};
