import { useEffect, useState } from 'react';

// services
import { OneTrust } from 'services/OneTrust';

// helpers
import { isAttributionFlow } from 'helpers/utils';

const DEFAULT_CONFIG = { childList: true, subtree: true };

export const useOneTrustLoaded = (selector = 'onetrust-consent-sdk') => {
    const [isOneTrustLoaded, setIsOneTrustLoaded] = useState(false);
    const [oneTrust, setOneTrust] = useState(null);
    const isAllAnalyticsAllowed = localStorage.getItem('allAnalyticInited') || false;


    new Promise(() => {
        if (document.getElementById(selector) && !isOneTrustLoaded) {
            setIsOneTrustLoaded(true);
        }

        new MutationObserver((_, observer) => {
            if (document.getElementById(selector) && !isOneTrustLoaded) {
                setIsOneTrustLoaded(true);
                observer.disconnect();
            }
        }).observe(document.body, DEFAULT_CONFIG);
    });

    useEffect(() => {
        if (isOneTrustLoaded) {
            const { OneTrust: instance } = window;

            setOneTrust(new OneTrust(instance));
        }
    }, [isOneTrustLoaded]);

    useEffect(() => {
        if (oneTrust && !isAllAnalyticsAllowed && !isAttributionFlow()) {
            oneTrust?.init();
        }

        // we need re-init analytic after page reloaded if it was already inited
        if (oneTrust && isAllAnalyticsAllowed) {
            oneTrust?.allowAll();
        }
    }, [oneTrust, isAllAnalyticsAllowed]);

    return { isOneTrustLoaded, oneTrust };
};

export default useOneTrustLoaded;
