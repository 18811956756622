import React from 'react';

// styles
import styles from './OnboardingStepper.module.css';

// img
import BackIcon from './img/icon-back.svg';

// components
import Logo from 'components/Logo';
import { Text, Box } from 'libComponents';

// types
import { OnboardingScreensList } from 'types/onboarding/onboardingStore';

function OnboardingStepper({
    onboardingScreensList,
    currentLocationName,
}: {
    onboardingScreensList: OnboardingScreensList;
    currentLocationName: string;
}) {
    const currentPageCount = onboardingScreensList[currentLocationName]?.index;
    const screensNumber = Object.keys(onboardingScreensList).length;

    const calculateProgressWidth = () => (currentPageCount / screensNumber) * 100 + '%';

    return (
        <Box>
            <div className={styles.top}>
                <div onClick={() => history.back()}>
                    <BackIcon className={styles.iconBack} />
                </div>
                <Logo />
                <div className={styles.pageCounter}>
                    {currentPageCount && <Text color="primary-default" text={`${currentPageCount}`} />}
                    <Text color="primary-default" text=" / " />
                    {screensNumber && <Text text={`${screensNumber}`} />}
                </div>
            </div>
            <div className={styles.progressBox}>
                <div className={styles.progressBar} style={{ width: calculateProgressWidth() }} />
            </div>
        </Box>
    );
}

export default OnboardingStepper;
