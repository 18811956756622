import * as actionTypes from './actionTypes';

// interfaces
import { PaymentStore } from 'interfaces/Store/paymentStore';

// constants
import { PAYMENT_DEFAULT_CURRENCY } from 'constants/payments';

import { InferValueTypes } from 'types/commonInterfaces';

// actions
import * as action from './actions';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const initialState: PaymentStore = {
    isLoading: false,
    paymentValidate: {},
    paymentMethod: 'PAYPAL',
    paymentConfig: {
        paypal: true,
        applaPay: true,
        sandbox: false,
    },
    solidInited: false,
    bankCardSrc: null,
    payPalLink: null,
    applePay: '',
    initPaymentData: null,
    checkoutOrderId: '',
    currentProduct: null,
    currency: PAYMENT_DEFAULT_CURRENCY,
    exclusiveOfferStatus: false,
    paymentType: null,
    selectedTrialPrice: 0.5,
    subscriptionId: null,
    upsellId: null,
    paymentDiscount: 0,
    validatePaymentData: null,
    oneClickPaymentPrice: null,
    isPaymentScreen: false,
};

const PaymentReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case actionTypes.SET_CURRENT_PRODUCT: {
            return {
                ...state,
                currentProduct: action.payload,
            };
        }
        case actionTypes.SET_TRIAL_PRICE:
            return {
                ...state,
                selectedTrialPrice: Number(action.payload),
            };
        case actionTypes.SET_ORDER_ID:
            return {
                ...state,
                checkoutOrderId: action.payload,
            };
        case actionTypes.SET_SUBSCRIPTION_ID:
            return {
                ...state,
                subscriptionId: action.payload,
            };
        case actionTypes.INIT_SOLID_PAYMENT:
            return {
                ...state,
                solidInited: action.payload,
            };
        case actionTypes.SET_LOADING_STATUS:
            return {
                ...state,
                isLoading: action.payload,
            };
        case actionTypes.SET_PAYMENT_DATA:
            return {
                ...state,
                initPaymentData: action.payload,
            };
        case actionTypes.VALIDATE_PAYMENT_DATA:
            return {
                ...state,
                validatePaymentData: action.payload,
            };
        case actionTypes.INIT_PAYMENT:
            return {
                ...state,
            };
        case actionTypes.SET_BANKCARD_SRC:
            return {
                ...state,
                bankCardSrc: action.payload,
            };

        case actionTypes.VALIDATE_PAYMENT:
            return {
                ...state,
                isLoading: true,
            };
        case actionTypes.CHANGE_PAYMENT_TYPE:
            return {
                ...state,
                paymentMethod: action.payload,
            };
        case actionTypes.SET_UPSELL_PRODUCT_PRICE:
            return {
                ...state,
                oneClickPaymentPrice: action.payload,
            };
        case actionTypes.SET_PAYMENT_CONFIG: {
            const newPaymentConfig = Object.assign({}, state.paymentConfig, action.payload);

            return {
                ...state,
                paymentConfig: newPaymentConfig,
            };
        }
        case actionTypes.VALIDATE_PAYMENT_FAIL:
            return {
                ...state,
                isLoading: false,
                paymentValidate: {
                    isValid: false,
                    message: action.payload,
                },
            };
        case actionTypes.SET_PAYMENT_CURRENCY:
            return { ...state, currency: action.payload };
        case actionTypes.SET_EXCLUSIVE_OFFER:
            return {
                ...state,
                exclusiveOfferStatus: action.payload,
            };
        case actionTypes.SET_PAYMENT_TYPE:
            localStorage.setItem('paymentType', action.payload);

            return { ...state, paymentType: action.payload };
        case actionTypes.SET_UPSELL_PRODUCT_ID:
            return {
                ...state,
                upsellId: action.payload,
            };
        case actionTypes.SET_PAYMENT_DISCOUNT:
            return { ...state, paymentDiscount: action.payload };
        case actionTypes.SET_IS_PAYMENT_SCREEN:
            return { ...state, isPaymentScreen: action.payload };
        default:
            return state;
    }
};

export default PaymentReducer;
